import services from "../axios/dbManag";

export const viewPersona = (id) => {
  return services.dbManag
    .post("/anagrafiche/persone/view", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewCorsiAggiornamentoList = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/aggiornamenti/list", {
      id_Persona: id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const listRinnovoAnnualeAlbo = (
  values
  /* id_persona,
  anno,
  importo_mora,
  tassa_reintegro */
) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/rinnovo/list", {
      ...values,
      /* id_persona,
      anno,
      importo_mora,
      tassa_reintegro, */
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addRinnovoAnnualeAlbo = (
  id_persona,
  anno,
  data_emissione,
  id_tipo_pagamento,
  descrizione,
  data_competenza,
  anni_rinnovi_importi,
  importo_reintegro,
  importo_mora,
  id_insegnante
) => {
  return services.dbManag
    .post("/economato/movimenti/albi/snm/rinnova", {
      id_persona,
      anno,
      data_emissione,
      id_tipo_pagamento,
      descrizione,
      data_competenza,
      anni_rinnovi_importi,
      importo_reintegro,
      importo_mora,
      id_insegnante,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addRinnovoAnnualeAlboInsegnante = (
  id_persona,
  anno,
  data_emissione
) => {
  return services.dbManag
    .post("/economato/movimenti/albi/snm/personale/rinnova", {
      id_persona,
      anno,
      data_emissione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getListaQualifiche = (id_Persona) => {
  return services.dbManag
    .post("/albi/persone/qualifiche/personale/list", {
      id_Persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
